import { deleteRow, editNewRows } from "redux/project-reducer";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { InputNumber, Select, Tooltip } from "antd";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InputComponent from "components/input-component";
import { Popover } from "antd";
import ProcurementSideMenuContent from "components/Procurement-SideMenu-Content/ProcurementSideMenuContent";

import SideMenu from "components/side-menu";
import Text from "components/text";
import commentIcon from "assets/images/sub-division-item-comment-icon.svg";
import lateIcon from "assets/images/late-icon.svg";
import moment, { isDate, weekdays } from "moment";
import pendingIcon from "assets/images/pending-icon.svg";

import styles from "./div.module.css";
import UndoIcon from "@mui/icons-material/Undo";
import { Checkbox } from "antd";
import { CaretRightOutlined, EnterOutlined } from "@ant-design/icons";
const CheckboxGroup = Checkbox.Group;

const IconShow = ({ icon }) => {
  return <>{icon}</>;
};

const handleFieldIcon = (status) => {
  if (status) {
    if (status == "late") {
      return <img src={lateIcon} alt="late" />;
    } else if (status == "pending") {
      return <img src={pendingIcon} alt="completed" />;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
const timeFormat = "MM-DD-YYYY";
const SubDivisionTableItem = React.memo(
  ({
    data,
    divName,
    subDivisionName,
    rowindex,
    toggleRowCheck,
    checkedRows,
    handleEditRow,
    handleEditNewRows,
    handleDeleteRow,
    isParent,
    isChild,
    childCount,
    isExpanded,
    onToggleExpand,
    className,
    nestSubmittals,
    matchCalculations,
    subDivItemState,
  }) => {
    // const dispatch = useDispatch();
    const { company_id, id } = useParams();

    console.log("item");
    const entryOrigin = data?.entryOrigin;

    useEffect(() => {
      if (subDivItemState?.[data.entryID]) {
        // console.log(
        //   subDivItemState,
        //   "CHECK DIVISION AND SUBDIVISION NAMES TO MATCH ENTRY'S"
        // );

        setSubDivItem(subDivItemState?.[data.entryID]);
      }
    }, [subDivItemState, data.entryID]);

    const { projectDetails, projectsAccessLevelValue } = useSelector(
      (state) => state.projectDetails
    );
    const { unsavedChanges } = useSelector((state) => state.projectDetails);
    const nonWorkingDays = { ...projectDetails.nonWorkingDays };
    const workingDays = { ...projectDetails.workingDays };
    const IsReadOnly = useMemo(
      () => projectsAccessLevelValue == 1,
      [projectDetails.usersInfo[0]]
    );
    const usersInfo = projectDetails.usersInfo[0];
    const warninyStyleValue = parseInt(usersInfo?.warningStyle);

    const alignedData = projectDetails.viewsInfo.alignedData;

    const taskIDsAssigned = usersInfo?.taskIDsAssigned?.flat();
    const isItemIDIncluded = taskIDsAssigned.includes(data["entryID"]); // checks if item ID is included in assigned tasks (read_only access only)

    const renderExpandButton = () => {
      if (!nestSubmittals) {
        return null;
      }
      if (isParent && childCount < 1) {
        return (
          <div
            className={`${styles.expandButton} ${
              isExpanded ? styles.expanded : ""
            }`}
            style={{
              background: "none",
              border: "none",
              padding: "4px",
              cursor: "cursor",
              opacity: "0",
              marginRight: "8px",
            }}
          >
            <CaretRightOutlined />
          </div>
        );
      }

      if (isParent && childCount > 0) {
        return (
          <button
            className={`${styles.expandButton} ${
              isExpanded ? styles.expanded : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              onToggleExpand(data.entryID);
            }}
            style={{
              background: "none",
              border: "none",
              padding: "4px",
              cursor: "pointer",
              transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
              marginRight: "8px",
            }}
          >
            <CaretRightOutlined />
          </button>
        );
      }

      if (!isParent) {
        return (
          <EnterOutlined
            style={{
              transform: "rotate(90deg)",
            }}
          />
        );
      }

      return null;
    };

    const handleNonWorkingDays = () => {
      let disabledDatesArray = [];

      Object.keys(nonWorkingDays).forEach((key) => {
        const holiday = nonWorkingDays[key];
        var currDate = moment(holiday["startDate"], "MM/DD/YYYY").startOf(
          "day"
        );
        var lastDate = moment(holiday["finishDate"], "MM/DD/YYYY").startOf(
          "day"
        );

        disabledDatesArray.push(moment(currDate).format(timeFormat));
        while (currDate.add(1, "days").diff(lastDate) < 0) {
          disabledDatesArray.push(moment(currDate).format(timeFormat));
        }
        disabledDatesArray.push(moment(lastDate).format(timeFormat));
      });

      let filteredDatesArray = [...new Set(disabledDatesArray)];
      return filteredDatesArray;
    };
    const handleWorkingDays = () => {
      // Convert object keys to an array
      const daysOfWeek = Object.keys(workingDays);

      // Move the first day to the end of the array
      const adjustingDayOffs = daysOfWeek.shift();
      daysOfWeek.push(adjustingDayOffs);

      // Convert the array back to an object
      const adjustedWorkingDays = daysOfWeek.reduce((acc, day, index) => {
        acc[day] = workingDays[day];
        return acc;
      }, {});
      return adjustedWorkingDays;
    };

    const handlePreviousAvailDate = (date) => {
      let weekends = handleWorkingDays();
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayName = daysOfWeek[new Date(date).getDay()];

      // let weekendIndex = new Date(date).getDay();
      let nonWorkingDaysArr = handleNonWorkingDays();
      let previousDate = moment(date)
        .subtract(1, "days")
        .startOf("day")
        .format(timeFormat);

      if (nonWorkingDaysArr.includes(date) || weekends[dayName] == "0") {
        while (nonWorkingDaysArr.includes(previousDate)) {
          previousDate = moment(previousDate)
            .subtract(1, "days")
            .startOf("day")
            .format(timeFormat);
        }
        while (weekends[new Date(previousDate).getDay()] == "0") {
          previousDate = moment(previousDate)
            .subtract(1, "days")
            .startOf("day")
            .format(timeFormat);
        }
        return previousDate;
      } else {
        return date;
      }
    };

    const handleNextAvailDate = (date) => {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayName = daysOfWeek[new Date(date).getDay()];
      let weekends = handleWorkingDays();
      // let weekendIndex = new Date(date).getDay();
      let nonWorkingDaysArr = handleNonWorkingDays();
      let nextavailDay = moment(date)
        .add(1, "days")
        .startOf("day")
        .format(timeFormat);
      if (nonWorkingDaysArr.includes(date) || weekends[dayName] == "0") {
        while (nonWorkingDaysArr.includes(nextavailDay)) {
          nextavailDay = moment(nextavailDay)
            .add(1, "days")
            .startOf("day")
            .format(timeFormat);
        }
        while (weekends[new Date(nextavailDay).getDay()] == "0") {
          nextavailDay = moment(nextavailDay)
            .add(1, "days")
            .startOf("day")
            .format(timeFormat);
        }
        return nextavailDay;
      } else {
        return date;
      }
    };

    const [changedColumnsList, setChangedColumnsList] = useState([]);

    const [SubDivItem, setSubDivItem] = useState({
      id: data["entryID"],
      specSection: data["specSection"],
      specNumber: data["specNumber"],
      specTitle: data["specTitle"],
      specRevision: data["specRevision"],

      specType: data["specType"],

      specResponse: data["specResponse"],

      responsibleContractor: data["responsibleContractor"],

      issueContract:
        matchCalculations && ((isParent && childCount > 0) || isChild)
          ? data["issueContract"]
          : data["dateRequiredOnSite"]
          ? handlePreviousAvailDate(
              moment(data.dateRequiredOnSite)
                .subtract(data.leadTime || 0, "weeks")
                .subtract(data.approvalTime || 0, "weeks")
                .subtract(data.submittalPrepTime || 0, "weeks")
                .format(timeFormat)
            )
          : "",
      // issueContract: moment(data["issueContract"]).isValid()
      //   ? moment(data["issueContract"]).format("MM-DD-YYYY")
      //   : "",

      contractIssuedRelease: moment(data["contractIssuedRelease"]).isValid()
        ? moment(data["contractIssuedRelease"]).format("MM-DD-YYYY")
        : "",

      submittalPrepTime:
        data["submittalPrepTime"] || projectDetails.reviewTime / 7,

      submittalRequired:
        matchCalculations && ((isParent && childCount > 0) || isChild)
          ? data["submittalRequired"]
          : data["dateRequiredOnSite"]
          ? handlePreviousAvailDate(
              moment(
                handlePreviousAvailDate(
                  moment(data["dateRequiredOnSite"])
                    .subtract(data["leadTime"] || 0, "weeks")
                    .format(timeFormat)
                )
              )
                .subtract(
                  data["approvalTime"] || projectDetails.approvalTime / 7 || 0,
                  "weeks"
                )
                .format(timeFormat)
            )
          : "",

      submittalIssued: moment(data["submittalIssued"]).isValid()
        ? moment(data["submittalIssued"]).format("MM-DD-YYYY")
        : "",

      approvalTime: data["approvalTime"] || projectDetails.approvalTime / 7,

      submittalApprovalDue:
        matchCalculations && ((isParent && childCount > 0) || isChild)
          ? data["submittalApprovalDue"]
          : data["dateRequiredOnSite"]
          ? handlePreviousAvailDate(
              moment(data["dateRequiredOnSite"])
                .subtract(data["leadTime"] || 0, "weeks")
                .format(timeFormat)
            )
          : "",

      submittalReturned: moment(data["submittalReturned"]).isValid()
        ? moment(data["submittalReturned"]).format("MM-DD-YYYY")
        : "",

      leadTime: data["leadTime"] || 2,

      scheduleID: data["scheduleID"],

      dateRequiredOnSite: moment(data["dateRequiredOnSite"]).isValid()
        ? moment(data["dateRequiredOnSite"]).format("MM-DD-YYYY")
        : "",

      projectedDeliveryDate:
        moment(data["projectedDeliveryDate"]).isValid() &&
        (moment(data["contractIssuedRelease"]).isValid() ||
          moment(data["submittalReturned"]).isValid() ||
          moment(data["submittalIssued"]).isValid() ||
          moment(data["issueContact"]).isValid() ||
          moment(data["submittalRequired"]).isValid() ||
          moment(data["submittalApprovalDue"]).isValid())
          ? moment(data["projectedDeliveryDate"]).format("MM-DD-YYYY")
          : "",

      subCommittedDeliveryDate: moment(
        data["subCommittedDeliveryDate"]
      ).isValid()
        ? moment(data["subCommittedDeliveryDate"]).format("MM-DD-YYYY")
        : "",

      actualDeliveryDate: moment(data["actualDeliveryDate"]).isValid()
        ? moment(data["actualDeliveryDate"]).format("MM-DD-YYYY")
        : "",
      custom1: data["custom1"],
      custom2: data["custom2"],
      custom3: data["custom3"],
      custom4: data["custom4"],
      custom5: data["custom5"],
      custom6: data["custom6"],
      custom7: data["custom7"],
      custom8: data["custom8"],
      custom9: data["custom9"],
      custom10: data["custom10"],
      custom11: data["custom11"],
      custom12: data["custom12"],
      custom13: data["custom13"],
      custom14: data["custom14"],
      custom15: data["custom15"],
      custom16: data["custom16"],
      custom17: data["custom17"],
      custom18: data["custom18"],
      custom19: data["custom19"],
      custom20: data["custom20"],
      custom21: data["custom21"],
      custom22: data["custom22"],
      custom23: data["custom23"],
      custom24: data["custom24"],
      custom25: data["custom25"],
      entryOrigin: data["entryOrigin"],
      parentGroup: data["parentGroup"],
      childGroup: data["childGroup"],
      childGroupCnt: data["childGroupCnt"],
      isEdited: false,
      isNew: data["isNew"] || false,
      div: divName,
      subDiv: subDivisionName,
      index: rowindex,
      isDeleted: data["isDeleted"],
      isHidden: data["isHidden"],
    });

    const handleEditingExceptionsForRealonly = useMemo(() => {
      const hasPermissionToEditLT =
        (usersInfo["leadTimeGranularPermission"] == "3" && isItemIDIncluded) ||
        projectsAccessLevelValue > 1;
      const hasPermissionToEditSubCommited =
        (usersInfo["subCommittedDeliveryDateGranularPermission"] == "3" &&
          isItemIDIncluded) ||
        projectsAccessLevelValue > 1;

      const hasPermissionToViewSubRequired =
        (usersInfo["greyOutSubRequiredDateGranularPermission"] == "0" &&
          isItemIDIncluded) ||
        projectsAccessLevelValue > 1;

      const exceptionsForReadOnly = {
        hasPermissionToEditLT: hasPermissionToEditLT,
        hasPermissionToEditSubCommited: hasPermissionToEditSubCommited,
        hasPermissionToViewSubRequired: hasPermissionToViewSubRequired,
      };
      return exceptionsForReadOnly;
    }, [usersInfo]);

    const handleChange = (objs = []) => {
      let tempColumnsList = objs
        .filter((item) => item.name !== "isEdited")
        .map((item) => item.name);

      setChangedColumnsList(tempColumnsList);

      let obj = {
        ...SubDivItem,
      };

      objs?.map((x) => {
        if (moment(x.value, timeFormat, true).isValid()) {
          let isProjectedDate = x.name == "projectedDeliveryDate";
          if (isProjectedDate) {
            let nextAvailDay = handleNextAvailDate(x.value);
            while (nextAvailDay !== handleNextAvailDate(nextAvailDay)) {
              nextAvailDay = handleNextAvailDate(nextAvailDay);
            }
            obj[x.name] = nextAvailDay;
          } else {
            let previousAvailDay = handlePreviousAvailDate(x.value);
            while (
              previousAvailDay !== handlePreviousAvailDate(previousAvailDay)
            ) {
              previousAvailDay = handlePreviousAvailDate(previousAvailDay);
            }
            obj[x.name] = previousAvailDay;
          }
        } else {
          obj[x.name] = x.value;
        }
      });

      setSubDivItem({
        ...obj,
      });

      if (obj["isNew"] && !obj["isHidden"]) {
        // dispatch(editNewRows(obj));
        handleEditNewRows(obj);
        return;
      } else if (obj["isEdited"]) {
        // dispatch(EditRow(obj));
        handleEditRow(obj);
      }
    };

    // calculating submittal approval due, submittal required & issue contract
    // useEffect(() => {
    //   if (!data["isHidden"]) {
    //     if (SubDivItem["Date Required On Site"]) {
    //       handleChange([
    //         {
    //           name: "Submittal Approval Due",
    //           value: moment(SubDivItem["Date Required On Site"])
    //             .subtract(SubDivItem["Lead Time"] || 0, "weeks")
    //             .format(timeFormat),
    //         },
    //         {
    //           name: "Submittal Required",
    //           value: moment(SubDivItem["Date Required On Site"])
    //             .subtract(SubDivItem["Lead Time"] || 0, "weeks")
    //             .subtract(
    //               SubDivItem["Approval Time"] ||
    //                 projectDetails.approvalTime / 7 ||
    //                 0,
    //               "weeks"
    //             )
    //             .format(timeFormat),
    //         },
    //         {
    //           name: "Issue Contract",
    //           value: moment(SubDivItem["Date Required On Site"])
    //             .subtract(SubDivItem["Lead Time"] || 0, "weeks")
    //             .subtract(
    //               SubDivItem["Approval Time"] ||
    //                 projectDetails.approvalTime / 7 ||
    //                 0,
    //               "weeks"
    //             )
    //             .subtract(
    //               SubDivItem["Submittal Prep Time"] ||
    //                 projectDetails.reviewTime / 7 ||
    //                 0,
    //               "weeks"
    //             )
    //             .format(timeFormat),
    //         },
    //       ]);
    //     }
    //   }
    // }, [SubDivItem["Date Required On Site"], SubDivItem["Lead Time"]]);

    // submittalApprovalDue vs submittal return
    const [submittalReturnedStatus, setsubmittalReturnedStatus] = useState("");
    useEffect(() => {
      if (!data["isHidden"]) {
        let approvalDue = SubDivItem["submittalApprovalDue"];
        let approvalReturn = SubDivItem["submittalReturned"];

        const currentDate = new Date();

        if (!approvalDue) {
          setsubmittalIssuedStatus("");
          setsubmittalReturnedStatus("");
          return;
        }

        if (!(approvalDue instanceof Date)) {
          approvalDue = new Date(approvalDue);
        }

        if (approvalReturn) {
          if (!(approvalReturn instanceof Date)) {
            approvalReturn = new Date(approvalReturn);
          }
        }

        let numOfDaysDiff = Math.ceil(
          (approvalDue.getTime() - currentDate.getTime()) /
            (24 * 60 * 60 * 1000)
        );

        if (!approvalReturn) {
          if (numOfDaysDiff > 7) {
            setsubmittalReturnedStatus("");
          } else if (numOfDaysDiff <= 7 && numOfDaysDiff > 0) {
            setsubmittalReturnedStatus("pending");
          } else if (numOfDaysDiff <= 0) {
            setsubmittalReturnedStatus("late");
          }
        }

        if (approvalReturn) {
          setsubmittalReturnedStatus("");
        }
      }
    }, [
      SubDivItem["submittalApprovalDue"],
      SubDivItem["leadTime"],
      SubDivItem["scheduleID"],
      SubDivItem["dateRequiredOnSite"],
    ]);

    // calculating submittal required
    // useEffect(() => {
    //   if (!data["isHidden"]) {
    //     let ApprovalTime = SubDivItem["Approval Time"];
    //     let submittalApproval = SubDivItem["Submittal Approval Due"];
    //     if (submittalApproval) {
    //       let submittalRequired = moment(submittalApproval)
    //         .subtract(ApprovalTime || 0, "weeks")
    //         .format(timeFormat);
    //       handleChange([
    //         { name: "Submittal Required", value: submittalRequired },
    //         {
    //           name: "Issue Contract",
    //           value: moment(SubDivItem["Date Required On Site"])
    //             .subtract(SubDivItem["Lead Time"] || 0, "weeks")
    //             .subtract(
    //               SubDivItem["Approval Time"] ||
    //                 projectDetails.approvalTime / 7 ||
    //                 0,
    //               "weeks"
    //             )
    //             .subtract(
    //               SubDivItem["Submittal Prep Time"] ||
    //                 projectDetails.reviewTime / 7 ||
    //                 0,
    //               "weeks"
    //             )
    //             .format(timeFormat),
    //         },
    //       ]);
    //     }
    //   }
    // }, [SubDivItem["Approval Time"], SubDivItem["Submittal Approval Due"]]);

    // submittal required(ref) vs submittal issued
    const [submittalIssuedStatus, setsubmittalIssuedStatus] = useState("");

    useEffect(() => {
      if (!data["isHidden"]) {
        let submittalRequired = SubDivItem["submittalRequired"];
        let submittalIssued = SubDivItem["submittalIssued"];

        const currentDate = new Date();

        if (!submittalRequired) {
          setsubmittalIssuedStatus("");
          return;
        }

        if (!(submittalRequired instanceof Date)) {
          submittalRequired = new Date(submittalRequired);
        }

        if (submittalIssued) {
          if (!(submittalIssued instanceof Date)) {
            submittalIssued = new Date(submittalIssued);
          }
        }

        let numOfDaysDiff = Math.ceil(
          (submittalRequired.getTime() - currentDate.getTime()) /
            (1000 * 3600 * 24)
        );
        if (!submittalIssued) {
          if (numOfDaysDiff > 7) {
            setsubmittalIssuedStatus("");
          } else if (numOfDaysDiff <= 7 && numOfDaysDiff > 0) {
            setsubmittalIssuedStatus("pending");
          } else if (numOfDaysDiff <= 0) {
            setsubmittalIssuedStatus("late");
          }
        }

        if (submittalIssued) {
          setsubmittalIssuedStatus("");
        }
      }
    }, [SubDivItem["submittalRequired"]]);

    const location = useLocation();
    const handleDelete = () => {
      let pathsArr = location.pathname.split("/");
      let code = localStorage.getItem("token");
      let loginEmail = localStorage.getItem("email");
      let values = {
        company_id: company_id,
        project_id: id,
        code,
        loginEmail,
        specNumber: SubDivItem["specNumber"],
        specRevision: SubDivItem["specRevision"],
        entryID: SubDivItem["id"],
        div: SubDivItem["div"],
        subDiv: SubDivItem["subDiv"],
      };

      handleChange([{ name: "isDeleted", value: true }]);
      // dispatch(
      //   deleteRow({
      //     values: values,
      //     index: rowindex,
      //   })
      // );

      handleDeleteRow({ values: values, index: rowindex });
    };
    const undoDelete = () => {
      let code = localStorage.getItem("token");
      let loginEmail = localStorage.getItem("email");
      let values = {
        company_id: company_id,
        project_id: id,
        code,
        loginEmail,
        specNumber: SubDivItem["specNumber"],
        specRevision: SubDivItem["specRevision"],
        entryID: SubDivItem["id"],
        div: SubDivItem["div"],
        subDiv: SubDivItem["subDiv"],
      };
      handleChange([{ name: "isDeleted", value: false }]);
      // dispatch(
      //   deleteRow({
      //     values: values,
      //     index: rowindex,
      //   })
      // );

      handleDeleteRow({ values: values, index: rowindex });
    };

    //Calculating Projected Date
    const [projectedDate, setProjectedDate] = useState("");
    const [projectedDeliveryDateStatus, setprojectedDeliveryDateStatus] =
      useState("");

    /* New projected Date Calculation */
    useEffect(() => {
      if (!data["isHidden"]) {
        const ContractIssuedRelease = moment(
          SubDivItem["contractIssuedRelease"]
        );
        const IssueContract = moment(SubDivItem["issueContract"]);
        const SubmittalIssued = moment(SubDivItem["submittalIssued"]);
        const SubmittalRequired = moment(SubDivItem["submittalRequired"]);
        const SubmittalReturned = moment(SubDivItem["submittalReturned"]);
        const SubmittalApprovalDue = moment(SubDivItem["submittalApprovalDue"]);

        const currentDate = new Date();

        const LT = moment
          .duration(SubDivItem["leadTime"] || 0, "weeks")
          .asWeeks();
        const dateRequiredOnSite = moment(SubDivItem["dateRequiredOnSite"]);
        const AT = moment
          .duration(SubDivItem["approvalTime"] || 0, "weeks")
          .asWeeks();
        const RT = moment
          .duration(SubDivItem["submittalPrepTime"] || 0, "weeks")
          .asWeeks();
        let calcDays;
        if (SubmittalReturned.isValid()) {
          calcDays = SubmittalReturned.clone().add(LT, "weeks").toDate();
        } else if (
          SubmittalApprovalDue.isValid() &&
          SubmittalApprovalDue.isBefore(currentDate)
        ) {
          calcDays = moment(currentDate).add(LT, "weeks").toDate();
        } else if (SubmittalIssued.isValid()) {
          calcDays = SubmittalIssued.clone()
            .add(LT, "weeks")
            .add(AT, "weeks")
            .toDate();
        } else if (
          SubmittalRequired.isValid() &&
          SubmittalRequired.isBefore(currentDate)
        ) {
          calcDays = moment(currentDate)
            .add(LT, "weeks")
            .add(AT, "weeks")
            .toDate();
        } else if (ContractIssuedRelease.isValid()) {
          calcDays = ContractIssuedRelease.clone()
            .add(LT, "weeks")
            .add(AT, "weeks")
            .add(RT, "weeks")
            .toDate();
        } else if (
          IssueContract.isValid() &&
          IssueContract.isBefore(currentDate)
        ) {
          calcDays = moment(currentDate)
            .add(RT, "weeks")
            .add(LT, "weeks")
            .add(AT, "weeks")
            .toDate();
        } else {
          calcDays = "";
        }
        const formatProjectedDateFinal = moment(calcDays).format("MM-DD-YYYY");

        setProjectedDate(formatProjectedDateFinal);
        const diffInDays = moment(dateRequiredOnSite._i).diff(
          moment(formatProjectedDateFinal),
          "days"
        );
        if (dateRequiredOnSite) {
          if (diffInDays < 0) {
            setprojectedDeliveryDateStatus("late");
          } else {
            setprojectedDeliveryDateStatus("");
          }
        }
        if (!dateRequiredOnSite || SubDivItem["actualDeliveryDate"]) {
          setprojectedDeliveryDateStatus("");
        }
      }
    }, [
      SubDivItem["submittalReturned"],
      SubDivItem["submittalIssued"],
      SubDivItem["leadTime"],
      SubDivItem["approvalTime"],
      SubDivItem["submittalPrepTime"],
      SubDivItem["scheduleID"],
      SubDivItem["issueContract"],
      SubDivItem["contractIssuedRelease"],
      SubDivItem["actualDeliveryDate"],
    ]);

    const [contractIssuedReleaseStatus, setcontractIssuedReleaseStatus] =
      useState(<></>);

    // Contract Issue Release Status
    useEffect(() => {
      if (!data["isHidden"]) {
        let contrctIssue = SubDivItem["issueContract"];
        let contrctIssueRelease = SubDivItem["contractIssuedRelease"];
        const currentDate = new Date();

        if (!contrctIssue) {
          setcontractIssuedReleaseStatus("");
          return;
        }

        if (!(contrctIssue instanceof Date)) {
          contrctIssue = new Date(contrctIssue);
        }

        if (contrctIssueRelease && !(contrctIssueRelease instanceof Date)) {
          contrctIssueRelease = new Date(contrctIssueRelease);
        }

        let numOfDaysDiff = Math.ceil(
          (contrctIssue.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
        );

        if (!contrctIssueRelease) {
          if (numOfDaysDiff > 7) {
            setcontractIssuedReleaseStatus("");
          } else if (numOfDaysDiff > 0 && numOfDaysDiff <= 7) {
            setcontractIssuedReleaseStatus("pending");
          } else if (numOfDaysDiff <= 0) {
            setcontractIssuedReleaseStatus("late");
          }
        }
        if (contrctIssueRelease) {
          setcontractIssuedReleaseStatus("");
        }
      }

      // console.log(numOfDaysDiff, "numOfDaysDiff");
    }, [SubDivItem["issueContract"], SubDivItem["contractIssuedRelease"]]);

    // Date Required onsite vs Actual date

    const [actualDeliveryDateStatus, setactualDeliveryDateStatus] =
      useState("");

    useEffect(() => {
      if (!data["isHidden"]) {
        let actual = SubDivItem["actualDeliveryDate"];
        let required = SubDivItem["dateRequiredOnSite"];
        const currentDate = new Date();

        if (!required) {
          setactualDeliveryDateStatus("");
          return;
        }

        if (!(required instanceof Date)) {
          required = new Date(required);
        }

        if (actual && !(actual instanceof Date)) {
          actual = new Date(actual);
        }

        let timeDifference = required.getTime() - currentDate.getTime();
        let numOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)); // milliseconds to days

        if (!actual) {
          if (numOfDays < 0) {
            setactualDeliveryDateStatus("late");
            // console.log("late");
          } else if (numOfDays < 7) {
            setactualDeliveryDateStatus("pending");
            // console.log("pending");
          } else {
            setactualDeliveryDateStatus("");
            // console.log("fasdfsdfsdfsdf");
          }
        } else {
          setactualDeliveryDateStatus("");
        }
      }
    }, [
      SubDivItem["dateRequiredOnSite"],
      SubDivItem["actualDeliveryDate"],
      SubDivItem["subCommittedDeliveryDate"],
    ]);

    const [subCommittedDeliveryDateStatus, setsubCommittedDeliveryDateStatus] =
      useState("");
    useEffect(() => {
      if (!data["isHidden"]) {
        let actual = SubDivItem["actualDeliveryDate"];
        let subCommited = SubDivItem["subCommittedDeliveryDate"];
        // let required = SubDivItem["dateRequiredOnSite"];
        const currentDate = new Date();

        if (!subCommited) {
          setsubCommittedDeliveryDateStatus("");
          return;
        }
        if (!(subCommited instanceof Date)) {
          subCommited = new Date(subCommited);
        }

        let timeDifference = subCommited.getTime() - currentDate.getTime();
        let numOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24)); // milliseconds to days
        if (!actual) {
          if (numOfDays > 7) {
            setsubCommittedDeliveryDateStatus("");
          } else if (numOfDays <= 7 && numOfDays > 0) {
            setsubCommittedDeliveryDateStatus("pending");
          } else if (numOfDays <= 0) {
            setsubCommittedDeliveryDateStatus("late");
          }
        } else {
          setsubCommittedDeliveryDateStatus("");
        }
      }
    }, [
      // SubDivItem["actualDeliveryDate"],
      SubDivItem["subCommittedDeliveryDate"],
      SubDivItem["actualDeliveryDate"],
    ]);

    // Reset calculations fields when dateRequiredOnSite clears out

    useEffect(() => {
      if (!data["isHidden"]) {
        if (!SubDivItem["dateRequiredOnSite"]) {
          handleChange([
            {
              name: "submittalApprovalDue",
              value: "",
            },
            {
              name: "submittalRequired",
              value: "",
            },
            {
              name: "issueContract",
              value: "",
            },
          ]);
        }
      }
    }, [SubDivItem["dateRequiredOnSite"]]);

    // projectedDeliveryDate
    useEffect(() => {
      if (!data["isHidden"]) {
        if (
          !SubDivItem["dateRequiredOnSite"] &&
          !SubDivItem["submittalIssued"] &&
          !SubDivItem["submittalReturned"] &&
          !SubDivItem["contractIssueRelease"]
        ) {
          handleChange([
            {
              name: "projectedDeliveryDate",
              value: "",
            },
          ]);
        } else {
          if (moment(projectedDate).isValid()) {
            handleChange([
              {
                name: "projectedDeliveryDate",
                value: projectedDate,
              },
            ]);
          }
        }
      }
    }, [projectedDate]);

    const [customRefresh, setcustomRefresh] = useState(false);
    const [scheduleIdError, setscheduleIdError] = useState("");

    useEffect(() => {
      if (!data["isHidden"]) {
        if (SubDivItem["scheduleID"]) {
          let scheduleIDs = [...projectDetails.schedule];

          // Find the element by scheduleID
          let findElement = scheduleIDs?.find(
            (obj) => SubDivItem["scheduleID"] in obj
          );

          // Extract the date from the found element
          let formattedElement = moment(
            findElement?.[SubDivItem["scheduleID"]]["startDate"]
          ).format(timeFormat);

          let formattedDateOnSite = moment(
            [SubDivItem?.["dateRequiredOnSite"]][0]
          ).format(timeFormat);

          let isValidDate = moment(formattedElement).isValid();

          // Check if the date is valid and handle changes
          if (findElement && formattedElement !== formattedDateOnSite) {
            setscheduleIdError(
              "The latest uploaded schedule changed this scheduleID’s date"
            );
            handleChange([
              { name: "dateRequiredOnSite", value: formattedElement },
            ]);
          } else if (!findElement && SubDivItem["scheduleID"]) {
            setscheduleIdError(
              "The latest uploaded schedule no longer has this ID"
            );
          } else if (
            formattedElement == formattedDateOnSite ||
            !SubDivItem["scheduleID"]
          ) {
            setscheduleIdError("");
          }

          // Handle invalid or empty dates
          if (!isValidDate) {
            handleChange([{ name: "dateRequiredOnSite", value: "" }]);
          }
        }
      }
    }, [SubDivItem["scheduleID"], customRefresh]);

    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
    // const [scheduleIdIndex, setscheduleIdIndex] = useState(null);
    const handleSideMenu = () => {
      setIsSideMenuVisible(true);
    };

    // handleScheduleId Options
    const sheduleOptions = React.useMemo(() => {
      const tempOptions = [
        ...projectDetails.schedule.map((x) => ({
          label: Object.keys(x)[0] + " - " + Object.values(x)[0]["taskName"],
          value: Object.keys(x)[0],
        })),
      ];
      const options = tempOptions.sort((a, b) => +a.value - +b.value);
      return options;
    }, []);

    const filterOption = (input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const onSearch = (value) => {
      // console.log("search:", value);
    };

    const onChange = (value) => {
      // console.log(value, "SELECTED VALUE");
      handleChange([
        { name: "scheduleID", value: value },
        { name: "isEdited", value: true },
      ]);
      setTimeout(() => {
        setcustomRefresh(!customRefresh);
      }, 100);
    };

    const title_tooltip = (
      <>
        <Text fontSize={14}>{SubDivItem["specTitle"]}</Text>
      </>
    );
    const schedule_tooltip = (
      <>
        <Text fontSize={14}>{scheduleIdError}</Text>
      </>
    );

    // const handleDeletedRowsStyle = useMemo(() => {
    //   const isDeleted = deletedItems?.some(
    //     (item) =>
    //       item["index"] == rowindex &&
    //       item.values["div"] == divName &&
    //       item.values["subDiv"] == subDivisionName
    //   );
    //   return isDeleted;
    // }, [deletedItems]);

    // const handleDeletedRowsStyle = React.useCallback(
    //   (rowindex, divName, subDivisionName) => {
    //     return deletedItems.some(
    //       (item) =>
    //         item["index"] === rowindex &&
    //         item.values["div"] === divName &&
    //         item.values["subDiv"] === subDivisionName
    //     );
    //   },
    //   [deletedItems]
    // );

    // const handleDeletedRowsStyle = React.useCallback(() => {
    //   return deletedItems.some(
    //     (item) =>
    //       item["index"] === rowindex &&
    //       item.values["div"] === divName &&
    //       item.values["subDiv"] === subDivisionName
    //   );
    // }, [deletedItems]);

    let handleDeletedRowsStyle = SubDivItem.isDeleted;

    const isDisabledRow = useMemo(() => {
      return projectDetails.itemsPendingApprovals?.includes(SubDivItem["id"]);
    }, [projectDetails.itemsPendingApprovals]);

    const excludedKeys = [
      "entryID",
      "specSection",
      "specTitle",
      "specNumber",
      "specRevision",
      "specType",
    ];

    // Convert alignedData object to an array, sort by index, and filter out excluded keys and hidden items
    const sortedColumns = Object.keys(alignedData)
      .filter(
        (key) =>
          !excludedKeys.includes(key) && alignedData[key].hidden === "false"
      )
      .sort((a, b) => alignedData[a].index - alignedData[b].index);

    // Extract formulas and dependencies
    const formulaColumns = Object.keys(alignedData).reduce((acc, columnKey) => {
      let formula = alignedData[columnKey].formula;

      if (formula) {
        // Extract the column dependencies from the formula
        const dependencies = formula.match(/(\w+)/g);
        acc[columnKey] = {
          formula,
          dependencies,
        };
      }
      return acc;
    }, {});

    // Find columns dependent on the changed column
    const findDependentColumns = (changedColumn) => {
      const dependents = [];

      // Loop through all columns with formulas
      Object.keys(formulaColumns).forEach((columnKey) => {
        const { dependencies } = formulaColumns[columnKey];

        // If the changed column is a dependency, mark it for recalculation
        if (dependencies.includes(changedColumn)) {
          dependents.push(columnKey);
        }
      });

      return dependents;
    };

    // Recalculate dependent columns and log old and new values
    const recalculateColumns = (changedColumn) => {
      // Find all dependent columns
      const allDependentColumns = findDependentColumns(changedColumn);

      //REMOVE PROJECT DELIVERY DATE
      const dependentColumns = allDependentColumns.filter(
        (column) => column !== "projectedDeliveryDate"
      );

      // Array to hold all column changes
      const columnChanges = [];

      dependentColumns.forEach((columnKey) => {
        if (columnKey !== "projectedDeliveryDate") {
          // console.log(`Column: ${columnKey}`);

          const { formula } = formulaColumns[columnKey];

          // Calculate new value
          const newValue = calculateValue(formula);

          // console.log(`Formula: ${formula}`);
          // console.log(`Old Value: ${SubDivItem[columnKey]}`);
          // console.log(`New Value: ${newValue}`);

          // console.log(columnKey, newValue, "HEREE");

          columnChanges.push({ name: columnKey, value: newValue });
        }
      });

      if (dependentColumns.length > 0) {
        // Add the isEdited field
        columnChanges.push({
          name: "isEdited",
          value: true,
        });

        handleColumnChange(columnChanges);
      }
    };

    const calculateValue = (formula) => {
      // Extract variable names from the formula
      const columnNames = formula.match(/[a-zA-Z_]\w*/g) || [];

      let evaluatedFormula = formula;
      let dateValue = null;
      let dateCount = 0;

      for (let columnName of columnNames) {
        if (SubDivItem.hasOwnProperty(columnName)) {
          let value = SubDivItem[columnName];

          const isDate = alignedData[columnName].type === "date";

          if (
            alignedData[columnName].type === "int" &&
            (value === null || value === undefined || value === "")
          ) {
            value = 0;
          }

          if (isDate) {
            // If more than one date, break
            if (dateCount > 0) {
              console.error(
                "More than one date found in the formula. Exiting."
              );
              console.log(formula, "????");

              return null;
            }

            dateCount++;

            // Assign the date value
            dateValue = moment(value, "MM-DD-YYYY", true);
            if (!dateValue.isValid() || !value) {
              console.error(
                "Invalid or empty date found in the formula. Exiting."
              );
              return "";
            }

            // Remove the date from the formula
            evaluatedFormula = evaluatedFormula.replace(
              new RegExp(`\\b${columnName}\\b`, "g"),
              ""
            );
          } else {
            const intValue = parseInt(value, 10);

            // Handle negative values with parentheses
            const formattedValue = intValue < 0 ? `(${intValue})` : intValue;

            // Replace the variable name with the actual integer value, including negative numbers
            evaluatedFormula = evaluatedFormula.replace(
              new RegExp(`\\b${columnName}\\b`, "g"),
              formattedValue
            );
          }
        }
      }

      // If there's more than one date or NaN, break
      if (dateCount > 1 || /NaN/.test(evaluatedFormula)) {
        console.error("Invalid formula or multiple dates found.");
        return "";
      }

      try {
        const integerResult = eval(evaluatedFormula.trim());

        // If the result is NaN, break
        if (isNaN(integerResult)) {
          console.error("The result is NaN.");
          return "";
        }

        // If the formula contains a date
        if (dateValue) {
          const finalDate = dateValue.add(integerResult, "weeks");
          return moment(finalDate).format(timeFormat);
        }

        // Return the integer result
        return integerResult;
      } catch (error) {
        console.error("Error evaluating the formula.", error);
        return "";
      }
    };

    // Handle column chang
    const handleColumnChange = (changedColumns) => {
      // console.log(changedColumns, "!!!");

      // Call handleChange once with the array of column changes
      handleChange([...changedColumns]);
    };

    useEffect(() => {
      if (!data["isHidden"]) {
        changedColumnsList.forEach((name) => {
          recalculateColumns(name);
        });
      }
      // console.log(changedColumnsList, "HAAAA");
    }, [changedColumnsList]);

    return (
      <>
        <Tooltip
          title="Item is pending approval"
          overlayStyle={{
            visibility: isDisabledRow ? "visible" : "hidden",
          }}
        >
          <tr
            className={`${styles.tableRow} ${
              handleDeletedRowsStyle ? styles.deletedRow : ""
            } ${isDisabledRow ? styles.disabledRow : null} ${styles.tableRow} ${
              className || ""
            }`}
            style={{ display: SubDivItem.isHidden ? "none" : "table-row" }}
          >
            {/* title */}
            {data["entryID"] ? (
              <td
                // className="d-flex justify-content-center w-100 h-100"
                style={{
                  position: "sticky",
                  left: -10,
                  zIndex: 1,
                  backgroundColor: !handleDeletedRowsStyle ? "white" : "grey",
                  // padding: "30px 20px",
                }}
              >
                <div
                  className={`d-flex justify-content-center w-100 h-50 ${
                    nestSubmittals ? "ms-3" : ""
                  }`}
                >
                  <Checkbox
                    className="align-self-center"
                    checked={checkedRows}
                    onChange={() => toggleRowCheck(data["entryID"])}
                    disabled={
                      (!handleEditingExceptionsForRealonly.hasPermissionToEditLT &&
                        !handleEditingExceptionsForRealonly.hasPermissionToEditSubCommited) ||
                      isDisabledRow ||
                      unsavedChanges
                    }
                  />
                  {renderExpandButton()}
                </div>
              </td>
            ) : (
              <td
                // className="d-flex justify-content-center w-100 h-100"
                style={{
                  position: "sticky",
                  left: -10,
                  zIndex: 1,
                  backgroundColor: !handleDeletedRowsStyle ? "white" : "grey",
                  // padding: "30px 20px",
                }}
              >
                <div className="d-flex justify-content-center w-100 h-100">
                  <Checkbox
                    className=" align-self-center"
                    checked={false}
                    disabled
                  />
                </div>
              </td>
            )}

            <td
              style={{
                position: "sticky",
                left: !nestSubmittals ? 40 : 63,
                zIndex: 1,
                backgroundColor: !handleDeletedRowsStyle ? "white" : "grey",
              }}
            >
              <Popover content={title_tooltip}>
                <InputComponent
                  className={` h-100 no-padding-input bg-transparent ${
                    entryOrigin === "Procore"
                      ? styles.orangeBorder
                      : entryOrigin === "Ex-Procore"
                      ? styles.redBorder
                      : styles.blueBorder
                  } ${nestSubmittals && isChild ? "ms-2 pe-3" : ""}`}
                  inputIconContainerClass={`flex-row-reverse`}
                  hasIcon
                  value={SubDivItem["specTitle"]}
                  maxLength={100}
                  customDisabledDates
                  Icon={
                    <img
                      src={commentIcon}
                      onClick={
                        projectsAccessLevelValue > 1 && !SubDivItem["isNew"]
                          ? handleSideMenu
                          : null
                      }
                      style={{
                        cursor:
                          projectsAccessLevelValue > 1 && !SubDivItem["isNew"]
                            ? "pointer"
                            : "not-allowed",
                      }}
                      alt=""
                    />
                  }
                  onChange={(e) => {
                    handleChange([
                      {
                        name: "specTitle",
                        value: e,
                      },
                      {
                        name: "isEdited",
                        value: true,
                      },
                    ]);
                  }}
                />
              </Popover>

              {/* <img src={commentIcon} width={20} /> */}
            </td>
            {/* Spec Number */}
            <td>
              <InputComponent
                className={`h-100 no-padding-input bg-transparent`}
                value={SubDivItem["specNumber"]}
                customDisabledDates
                maxLength={100}
                onChange={(e) => {
                  handleChange([
                    {
                      name: "specNumber",
                      value: e,
                    },
                    {
                      name: "isEdited",
                      value: true,
                    },
                  ]);
                }}
              />
            </td>
            {/* Revision */}
            <td>
              <InputComponent
                className={`h-100 no-padding-input bg-transparent`}
                value={SubDivItem["specRevision"]}
                customDisabledDates
                maxLength={100}
                onChange={(e) => {
                  handleChange([
                    {
                      name: "specRevision",
                      value: e,
                    },
                    {
                      name: "isEdited",
                      value: true,
                    },
                  ]);
                }}
              />
            </td>
            {/* specType */}

            <td>
              <InputComponent
                className={`h-100 no-padding-input bg-transparent`}
                value={SubDivItem["specType"]}
                customDisabledDates
                maxLength={100}
                onChange={(e) => {
                  handleChange([
                    {
                      name: "specType",
                      value: e,
                    },
                    {
                      name: "isEdited",
                      value: true,
                    },
                  ]);
                }}
              />
            </td>

            {sortedColumns.map((key) => {
              const dataValue = SubDivItem[key] || "";
              const isHidden = alignedData[key].hidden === "true";

              if (!isHidden) {
                return (
                  <td key={key}>
                    {alignedData[key].type === "string" &&
                      alignedData[key].editable === "false" && (
                        <Popover content={dataValue}>
                          <div
                            className={`${styles.Tabledata} d-flex align-items-center gap-2`}
                            style={{ color: IsReadOnly ? "grey" : "black" }}
                          >
                            <Text className={`${styles.tableText}`}>
                              {dataValue || ""}
                            </Text>
                          </div>
                        </Popover>
                      )}
                    {key !== "scheduleID" &&
                      alignedData[key].type === "string" &&
                      alignedData[key].editable === "true" && (
                        <Popover content={dataValue}>
                          <InputComponent
                            className={`h-100 no-padding-input bg-transparent`}
                            value={dataValue}
                            customDisabledDates
                            maxLength={100}
                            onChange={(e) => {
                              handleColumnChange([
                                {
                                  name: key,
                                  value: e,
                                },
                                {
                                  name: "isEdited",
                                  value: true,
                                },
                              ]);
                            }}
                          />
                        </Popover>
                      )}
                    {alignedData[key].type === "date" &&
                      alignedData[key].editable === "false" && (
                        <div
                          className={`${styles.Tabledata}
                    d-flex align-items-center gap-2  
                    
                    ${
                      key === "submittalIssued" &&
                      warninyStyleValue === 1 &&
                      submittalIssuedStatus === "late"
                        ? `${styles.lateColored}`
                        : key === "submittalIssued" &&
                          warninyStyleValue === 1 &&
                          submittalIssuedStatus === "pending"
                        ? `${styles.pendingColored}`
                        : key === "submittalReturned" &&
                          warninyStyleValue === 1 &&
                          submittalReturnedStatus === "late"
                        ? `${styles.lateColored}`
                        : key === "submittalReturned" &&
                          warninyStyleValue === 1 &&
                          submittalReturnedStatus === "pending"
                        ? `${styles.pendingColored}`
                        : key === "projectedDeliveryDate" &&
                          warninyStyleValue === 1 &&
                          projectedDeliveryDateStatus === "late"
                        ? `${styles.lateColored}`
                        : key === "projectedDeliveryDate" &&
                          warninyStyleValue === 1 &&
                          projectedDeliveryDateStatus === "pending"
                        ? `${styles.pendingColored}`
                        : `${styles.ff}`
                    }
                    `}
                          style={{
                            justifyContent: moment(SubDivItem[key]).isValid()
                              ? "center"
                              : "flex-start",
                            color:
                              IsReadOnly || isDisabledRow ? "grey" : "black",
                          }}
                        >
                          <IconShow
                            icon={
                              key === "submittalIssued"
                                ? handleFieldIcon(submittalIssuedStatus)
                                : key === "submittalReturned"
                                ? handleFieldIcon(submittalReturnedStatus)
                                : key === "projectedDeliveryDate"
                                ? handleFieldIcon(projectedDeliveryDateStatus)
                                : null
                            }
                          />
                          {key === "submittalRequired" ? (
                            <Text className={`${styles.tableText}`}>
                              {moment(
                                SubDivItem["submittalRequired"]
                              ).isValid() &&
                              handleEditingExceptionsForRealonly.hasPermissionToViewSubRequired
                                ? moment(
                                    SubDivItem["submittalRequired"]
                                  ).format("MM-DD-YYYY")
                                : ""}
                            </Text>
                          ) : (
                            <Text className={`${styles.tableText}`}>
                              {moment(SubDivItem[key]).isValid()
                                ? moment(SubDivItem[key]).format("MM-DD-YYYY")
                                : ""}
                            </Text>
                          )}
                        </div>
                      )}
                    {alignedData[key].type === "date" &&
                      alignedData[key].editable === "true" && (
                        <InputComponent
                          className={`h-100 no-padding-input ${
                            key === "subCommittedDeliveryDate" &&
                            (handleEditingExceptionsForRealonly.hasPermissionToEditLT &&
                            isDisabledRow
                              ? styles.disabledRow
                              : styles.exception)
                          }
                        ${
                          key === "actualDeliveryDate" &&
                          warninyStyleValue === 1 &&
                          actualDeliveryDateStatus === "late"
                            ? styles.lateColored
                            : key === "actualDeliveryDate" &&
                              warninyStyleValue === 1 &&
                              actualDeliveryDateStatus === "pending"
                            ? styles.pendingColored
                            : key === "subCommittedDeliveryDate" &&
                              warninyStyleValue === 1 &&
                              subCommittedDeliveryDateStatus === "late"
                            ? styles.lateColored
                            : key === "subCommittedDeliveryDate" &&
                              warninyStyleValue === 1 &&
                              subCommittedDeliveryDateStatus === "pending"
                            ? styles.pendingColored
                            : key === "contractIssuedRelease" &&
                              warninyStyleValue === 1 &&
                              contractIssuedReleaseStatus === "late"
                            ? styles.lateColored
                            : key === "contractIssuedRelease" &&
                              warninyStyleValue === 1 &&
                              contractIssuedReleaseStatus === "pending"
                            ? styles.pendingColored
                            : styles.ff
                        }
                      `}
                          Icon={
                            key === "actualDeliveryDate"
                              ? handleFieldIcon(actualDeliveryDateStatus)
                              : key === "subCommittedDeliveryDate"
                              ? handleFieldIcon(subCommittedDeliveryDateStatus)
                              : key === "contractIssuedRelease"
                              ? handleFieldIcon(contractIssuedReleaseStatus)
                              : null
                          }
                          type={dataValue ? "date" : "text"}
                          value={dataValue}
                          isDate={true}
                          disabled={
                            key === "subCommittedDeliveryDate"
                              ? !handleEditingExceptionsForRealonly.hasPermissionToEditSubCommited ||
                                isDisabledRow
                              : IsReadOnly || isDisabledRow
                          }
                          customDisabledDates
                          disabledDate={
                            key === "actualDeliveryDate" ||
                            key === "contractIssuedRelease"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            let Changes = [
                              { name: key, value: e },
                              ...(key === "dateRequiredOnSite"
                                ? [{ name: "scheduleID", value: "" }]
                                : []),
                              { name: "isEdited", value: true },
                            ];

                            handleColumnChange(Changes);
                            setcustomRefresh(!customRefresh);
                          }}
                        />
                      )}
                    {alignedData[key].type === "int" &&
                      alignedData[key].editable === "false" && (
                        <Popover content={dataValue}>
                          <div
                            className={`${styles.Tabledata} d-flex align-items-center gap-2`}
                            style={{ color: IsReadOnly ? "grey" : "black" }}
                          >
                            <Text className={`${styles.tableText}`}>
                              {dataValue || ""}
                            </Text>
                          </div>
                        </Popover>
                      )}
                    {alignedData[key].type === "int" &&
                      alignedData[key].editable === "true" && (
                        <Popover content={dataValue}>
                          <div
                            className={`
                      ${
                        key === "leadTime" &&
                        (!handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                          isDisabledRow)
                          ? styles.disabledRow
                          : styles.exception
                      }
                      ${
                        key !== "leadTime" && (IsReadOnly || isDisabledRow)
                          ? styles.disabledRow
                          : styles.exception
                      }
                      ${
                        alignedData[key].label.length < 3
                          ? "rounded-2 w-100"
                          : "h-100 rounded-2 w-100"
                      }
                    `}
                            style={{
                              border: "1px solid #C8C8C8",
                            }}
                          >
                            <InputNumber
                              className={`h-100 w-100 border-0 bg-transparent  ${
                                alignedData[key].label.length < 3 && "py-1"
                              } border-2`}
                              size="large"
                              style={{
                                textAlignLast: "center",
                                marginTop:
                                  alignedData[key].label.length > 3
                                    ? "4px"
                                    : "0px",
                                boxShadow: "none",
                                opacity:
                                  (key !== "leadTime" &&
                                    (IsReadOnly || isDisabledRow)) ||
                                  (key === "leadTime" &&
                                    (!handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                                      isDisabledRow))
                                    ? "50%"
                                    : "100%",
                              }}
                              type="number"
                              value={dataValue}
                              customDisabledDates
                              controls={false}
                              disabled={
                                key === "leadTime"
                                  ? !handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                                    isDisabledRow
                                  : IsReadOnly
                              }
                              onChange={(e) => {
                                handleColumnChange([
                                  { name: key, value: e },
                                  { name: "isEdited", value: true },
                                ]);
                              }}
                            />
                            {/* <InputComponent
                            className={`h-100 w-100 border-0 bg-transparent ${
                              alignedData[key].label.length < 3 && "p-0"
                            } border-2`}
                            size="large"
                            inputClassName="text-center"
                            value={dataValue}
                            customDisabledDates
                            disabled={
                              key === "leadTime" &&
                              (!handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                                isDisabledRow)
                            }
                            onChange={(e) => {
                              handleColumnChange([
                                { name: key, value: e },
                                { name: "isEdited", value: true },
                              ]);
                            }}
                          /> */}
                          </div>
                        </Popover>
                      )}
                    {key === "scheduleID" && (
                      <>
                        {scheduleIdError ? (
                          <Popover content={schedule_tooltip}>
                            <Select
                              className={`${styles.sheduleSelect} ${
                                (IsReadOnly || isDisabledRow) &&
                                styles.customDisabled
                              }`}
                              showSearch
                              bordered={false}
                              size="large"
                              placement="bottomLeft"
                              placeholder={SubDivItem["scheduleID"]}
                              optionFilterProp="children"
                              onChange={onChange}
                              allowClear={
                                SubDivItem["scheduleID"] &&
                                SubDivItem["scheduleID"].length > 1
                              }
                              onSearch={onSearch}
                              filterOption={filterOption}
                              options={sheduleOptions}
                              value={SubDivItem["scheduleID"]}
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: "200px" }}
                            />
                          </Popover>
                        ) : (
                          <Select
                            className={`${styles.sheduleSelect} ${
                              (IsReadOnly || isDisabledRow) &&
                              styles.customDisabled
                            }`}
                            showSearch
                            bordered={false}
                            size="large"
                            placement="bottomLeft"
                            placeholder={SubDivItem["scheduleID"]}
                            optionFilterProp="children"
                            onChange={onChange}
                            allowClear={
                              SubDivItem["scheduleID"] &&
                              SubDivItem["scheduleID"].length > 1
                            }
                            onSearch={onSearch}
                            filterOption={filterOption}
                            options={sheduleOptions}
                            value={SubDivItem["scheduleID"]}
                            dropdownMatchSelectWidth={false}
                            dropdownStyle={{ width: "200px" }}
                          />
                        )}
                      </>
                    )}
                  </td>
                );
              } else {
                return null; // if hidden
              }
            })}

            <td className={`${styles.actions} text-center`}>
              {!SubDivItem.specNumber &&
                (handleDeletedRowsStyle ? (
                  <span
                    className="d-flex justify-content-center"
                    style={{ color: "blue" }}
                    onClick={() => undoDelete()}
                  >
                    <UndoIcon className="text-center pointer" />
                  </span>
                ) : (
                  <span className="d-flex justify-content-center">
                    <DeleteOutlineOutlinedIcon
                      className={`align-content-center pointer ${
                        projectsAccessLevelValue < 3 ||
                        handleDeletedRowsStyle ||
                        isDisabledRow
                          ? styles.disabledDeleteButton
                          : styles.deleteButton
                      }`}
                      onClick={
                        projectsAccessLevelValue < 3 || isDisabledRow
                          ? null
                          : handleDelete
                      }
                    />
                  </span>
                ))}
            </td>
          </tr>
        </Tooltip>
        {isSideMenuVisible && (
          <SideMenu
            show={isSideMenuVisible}
            setShow={setIsSideMenuVisible}
            isRight={true}
            title={SubDivItem["div"]}
            isSmall={false}
            subTitle={SubDivItem["specSection"]}
            taskId={SubDivItem["id"]}
          >
            <ProcurementSideMenuContent
              accessLevel={projectsAccessLevelValue}
              content={SubDivItem}
            />
          </SideMenu>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.data === nextProps.data &&
      prevProps.checkedRows === nextProps.checkedRows &&
      prevProps.subDivItemState === nextProps.subDivItemState &&
      prevProps.isExpanded === nextProps.isExpanded &&
      prevProps.className === nextProps.className
    );
  }
);
export default SubDivisionTableItem;
