import React, { useState } from "react";
import { addHiddenRows, showHiddenRow } from "redux/project-reducer";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/loading-component";
import { Popover } from "antd";
import SubDivisionTableItem from "./sub-division-table-item";
import Text from "components/text";
import addIcon from "assets/images/add-sub-division-item.svg";
import styles from "./div.module.css";
import { useEffect } from "react";
import { Checkbox } from "antd";
import moment from "moment";

const SubDivisionTable = ({
  collapsed,
  data = [],
  divName,
  subDivisionName,
  setcollapsed,
  name,
  checkedRows,
  setCheckedRows,
  setEditedItems,
  setNewItems,
  newItems,
  deletedItems,
  setDeletedItems,
}) => {
  const dispatch = useDispatch();
  const { projectsAccessLevelValue, projectDetails, tableRows } = useSelector(
    (state) => state.projectDetails
  );

  const [tableRowsData, setTableRowsData] = useState(projectDetails?.content);

  const { unsavedChanges } = useSelector((state) => state.projectDetails);

  const alignedData = projectDetails.viewsInfo.alignedData;

  const nestSubmittals = parseInt(
    projectDetails?.settingsInfo[0]?.groupingInfo?.nestSubmittals
  );
  const matchCalculations = parseInt(
    projectDetails?.settingsInfo[0]?.groupingInfo?.overwriteCalculations
  );

  // console.log(nestSubmittals, "NEST??");

  const [tableVisible, setTableVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedParentGroups, setExpandedParentGroups] = useState([]);

  const [subDivItemState, setSubDivItemState] = useState(null);

  const handleSetSubDivItem = (newState) => {
    setSubDivItemState(newState);
  };

  useEffect(() => {
    dispatch(addHiddenRows({ subDivisionName: subDivisionName, data: data }));

    setLoading(true);

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => clearTimeout(timeout);
  }, [data, dispatch, subDivisionName]);

  const toggleTableVisibility = (e) => {
    e.stoppropagation();
    setTableVisible((prevState) => !prevState);
  };

  const toggleRowCheck = React.useCallback(
    (entryID) => {
      setCheckedRows((prevCheckedRows) => ({
        ...prevCheckedRows,
        [entryID]: !prevCheckedRows[entryID],
      }));
    },
    [setCheckedRows]
  );

  const toggleRowExpansion = React.useCallback((entryID, parentGroup) => {
    setExpandedRows((prev) => ({
      ...prev,
      [entryID]: !prev[entryID],
    }));

    setExpandedParentGroups((prev) =>
      prev.includes(parentGroup)
        ? prev.filter((group) => group !== parentGroup)
        : [...prev, parentGroup]
    );
  }, []);

  const toggleCheckAll = () => {
    const newCheckedRows = { ...checkedRows };
    let allChecked = true;

    data.forEach((item) => {
      if (item.specSection === subDivisionName) {
        if (!checkedRows[item.entryID]) {
          allChecked = false;
        }
      }
    });

    data.forEach((item) => {
      if (item.specSection === subDivisionName) {
        if (projectDetails.itemsPendingApprovals.includes(item.entryID)) {
          // Uncheck if entryID is in itemsPendingApprovals
          newCheckedRows[item.entryID] = false;
        } else {
          // Otherwise, toggle based on allChecked
          newCheckedRows[item.entryID] = !allChecked;
        }
      }
    });

    setCheckedRows(newCheckedRows);
  };

  const allChecked = data.every(
    (item) => item.specSection === subDivisionName && checkedRows[item.entryID]
  );

  const noneChecked = data.every(
    (item) => item.specSection === subDivisionName && !checkedRows[item.entryID]
  );

  const indeterminate = !allChecked && !noneChecked;

  const customColumns = [];

  for (let i = 1; i <= 25; i++) {
    const customKey = `custom${i}`;
    if (alignedData[customKey]?.hidden === "false") {
      customColumns.push(
        <th scope="col" key={customKey}>
          <Popover content={alignedData[customKey]?.description || ""}>
            <div className={`${styles.tableHeadBg}`}>
              <Text className={`${styles.tableHeadText}`} dontWrap={false}>
                {alignedData[customKey]?.label || `Custom ${i}`}
              </Text>
            </div>
          </Popover>
        </th>
      );
    }
  }

  const excludedKeys = [
    "entryID",
    "specSection",
    "specTitle",
    "specNumber",
    "specRevision",
    "specType",
  ];

  // Convert alignedData object to an array, sort by index, and filter out excluded keys and hidden items
  const sortedColumns = Object.keys(alignedData)
    .filter(
      (key) =>
        !excludedKeys.includes(key) && alignedData[key].hidden === "false"
    )
    .sort((a, b) => alignedData[a].index - alignedData[b].index);

  // new row template
  let newRow = (name) => ({
    entryID: "",
    specSection: name,
    specNumber: "",
    specTitle: "",
    specRevision: "",
    specType: "",
    specResponse: "",
    responsibleContractor: "",
    issueContract: "",
    contractIssuedRelease: "",
    submittalPrepTime: "",
    submittalRequired: "",
    submittalIssued: "",
    approvalTime: "",
    submittalApprovalDue: "",
    submittalReturned: "",
    leadTime: "",
    scheduleID: "",
    dateRequiredOnSite: "",
    projectedDeliveryDate: "",
    subCommittedDeliveryDate: "",
    actualDeliveryDate: "",
    custom1: "",
    custom2: "",
    custom3: "",
    custom4: "",
    custom5: "",
    custom6: "",
    custom7: "",
    custom8: "",
    custom9: "",
    custom10: "",
    custom11: "",
    custom12: "",
    custom13: "",
    custom14: "",
    custom15: "",
    custom16: "",
    custom17: "",
    custom18: "",
    custom19: "",
    custom20: "",
    custom21: "",
    custom22: "",
    custom23: "",
    custom24: "",
    custom25: "",
    entryOrigin: "Koncurent",
    parentGroup: "",
    childGroup: "",
    childGroupCnt: 0,
    isEdited: false,
    div: "",
    subDiv: "",
    index: "",
    isNew: true,
    isDeleted: false,
    isHidden: false,
  });

  const createNewRow = (subDivisionName) => {
    setNewItems((prevItems) => {
      const newIndex =
        (tableRows[subDivisionName]?.length || 0) +
        prevItems.filter((item) => item.subDiv === subDivisionName).length;

      return [
        ...prevItems,
        {
          ...newRow(subDivisionName),
          subDiv: subDivisionName,
          index: newIndex,
        },
      ];
    });
  };

  const timeFormat = "MM-DD-YYYY";
  const nonWorkingDays = { ...projectDetails.nonWorkingDays };
  const workingDays = { ...projectDetails.workingDays };

  const handleNonWorkingDays = () => {
    let disabledDatesArray = [];

    Object.keys(nonWorkingDays).forEach((key) => {
      const holiday = nonWorkingDays[key];
      var currDate = moment(holiday["startDate"], "MM/DD/YYYY").startOf("day");
      var lastDate = moment(holiday["finishDate"], "MM/DD/YYYY").startOf("day");

      disabledDatesArray.push(moment(currDate).format(timeFormat));
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        disabledDatesArray.push(moment(currDate).format(timeFormat));
      }
      disabledDatesArray.push(moment(lastDate).format(timeFormat));
    });

    let filteredDatesArray = [...new Set(disabledDatesArray)];
    return filteredDatesArray;
  };
  const handleWorkingDays = () => {
    // Convert object keys to an array
    const daysOfWeek = Object.keys(workingDays);

    // Move the first day to the end of the array
    const adjustingDayOffs = daysOfWeek.shift();
    daysOfWeek.push(adjustingDayOffs);

    // Convert the array back to an object
    const adjustedWorkingDays = daysOfWeek.reduce((acc, day, index) => {
      acc[day] = workingDays[day];
      return acc;
    }, {});
    return adjustedWorkingDays;
  };

  const handlePreviousAvailDate = (date) => {
    let weekends = handleWorkingDays();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[new Date(date).getDay()];

    // let weekendIndex = new Date(date).getDay();
    let nonWorkingDaysArr = handleNonWorkingDays();
    let previousDate = moment(date)
      .subtract(1, "days")
      .startOf("day")
      .format(timeFormat);

    if (nonWorkingDaysArr.includes(date) || weekends[dayName] == "0") {
      while (nonWorkingDaysArr.includes(previousDate)) {
        previousDate = moment(previousDate)
          .subtract(1, "days")
          .startOf("day")
          .format(timeFormat);
      }
      while (weekends[new Date(previousDate).getDay()] == "0") {
        previousDate = moment(previousDate)
          .subtract(1, "days")
          .startOf("day")
          .format(timeFormat);
      }
      return previousDate;
    } else {
      return date;
    }
  };

  const handleEditRow = React.useCallback(
    (obj) => {
      const calculateDates = (data) => {
        if (!data.dateRequiredOnSite) {
          return {
            issueContract: "",
            submittalRequired: "",
            submittalApprovalDue: "",
          };
        }

        const issueContract = handlePreviousAvailDate(
          moment(data.dateRequiredOnSite)
            .subtract(data.leadTime || 0, "weeks")
            .subtract(data.approvalTime || 0, "weeks")
            .subtract(data.submittalPrepTime || 0, "weeks")
            .format(timeFormat)
        );

        const submittalApprovalDue = handlePreviousAvailDate(
          moment(data.dateRequiredOnSite)
            .subtract(data.leadTime || 0, "weeks")
            .format(timeFormat)
        );

        const submittalRequired = handlePreviousAvailDate(
          moment(
            handlePreviousAvailDate(
              moment(data.dateRequiredOnSite)
                .subtract(data.leadTime || 0, "weeks")
                .format(timeFormat)
            )
          )
            .subtract(
              data.approvalTime || projectDetails.approvalTime / 7 || 0,
              "weeks"
            )
            .format(timeFormat)
        );

        return {
          issueContract,
          submittalRequired,
          submittalApprovalDue,
        };
      };

      if (matchCalculations) {
        setTableRowsData((prevTableRowsData) => {
          const updatedRows = JSON.parse(JSON.stringify(prevTableRowsData));

          if (!updatedRows[divName]) updatedRows[divName] = {};
          if (!updatedRows[divName][obj.subDiv]) {
            updatedRows[divName][obj.subDiv] = [];
          }

          const subDivRows = updatedRows[divName][obj.subDiv];
          const rowIndex = subDivRows.findIndex(
            (row) => row.entryID === obj.id
          );

          if (rowIndex !== -1) {
            const updatedRow = { ...subDivRows[rowIndex], ...obj };
            subDivRows[rowIndex] = updatedRow;

            const isParent =
              updatedRow.parentGroup && updatedRow.childGroupCnt > 0;
            const isChild = updatedRow.childGroup;

            if (isParent || isChild) {
              let familyRows = [];
              const groupId = isParent
                ? updatedRow.parentGroup
                : updatedRow.childGroup;

              // Collect family rows
              if (isParent) {
                familyRows = [updatedRow];
                for (let i = rowIndex + 1; i < subDivRows.length; i++) {
                  if (subDivRows[i].childGroup === updatedRow.parentGroup) {
                    familyRows.push(subDivRows[i]);
                  } else {
                    break;
                  }
                }
              } else if (isChild) {
                for (let i = rowIndex - 1; i >= 0; i--) {
                  if (subDivRows[i].parentGroup === updatedRow.childGroup) {
                    familyRows = [subDivRows[i]];
                    break;
                  }
                }
                if (familyRows.length > 0) {
                  const parentIndex = subDivRows.indexOf(familyRows[0]);
                  for (let i = parentIndex + 1; i < subDivRows.length; i++) {
                    if (subDivRows[i].childGroup === updatedRow.childGroup) {
                      familyRows.push(subDivRows[i]);
                    } else {
                      break;
                    }
                  }
                }
              }

              // Calculate dates
              const editedRowDates = calculateDates(obj);
              const familyCalculatedDates = familyRows.map((row) => ({
                ...calculateDates(row),
                entryID: row.entryID,
              }));

              // Find soonest dates
              const soonestDates = {
                issueContract: editedRowDates.issueContract,
                submittalRequired: editedRowDates.submittalRequired,
                submittalApprovalDue: editedRowDates.submittalApprovalDue,
              };

              familyCalculatedDates.forEach((dates) => {
                if (dates.entryID !== obj.id) {
                  Object.keys(soonestDates).forEach((field) => {
                    if (
                      dates[field] &&
                      (!soonestDates[field] ||
                        moment(dates[field]).isBefore(
                          moment(soonestDates[field])
                        ))
                    ) {
                      soonestDates[field] = dates[field];
                    }
                  });
                }
              });

              // Update all family rows and editedItems
              setEditedItems((prevItems) => {
                const updatedItems = [...prevItems];

                familyRows.forEach((row) => {
                  const rowIndex = subDivRows.findIndex(
                    (r) => r.entryID === row.entryID
                  );
                  if (rowIndex !== -1) {
                    const updatedFamilyRow = {
                      ...row,
                      ...soonestDates,
                      id: row?.entryID,
                      isEdited: true,
                      isNew: false,
                      isHidden: false,
                      div: updatedRow?.div,
                      subDiv: updatedRow?.subDiv,
                    };
                    subDivRows[rowIndex] = updatedFamilyRow;

                    setSubDivItemState((prevState) => ({
                      ...prevState,
                      [row.entryID]: updatedFamilyRow,
                    }));

                    const existingItemIndex = updatedItems.findIndex(
                      (item) =>
                        item.div === updatedFamilyRow.div &&
                        item.subDiv === updatedFamilyRow.subDiv &&
                        item.id === updatedFamilyRow.id
                    );

                    if (existingItemIndex === -1) {
                      updatedItems.push(updatedFamilyRow);
                    } else {
                      updatedItems[existingItemIndex] = updatedFamilyRow;
                    }
                  }
                });

                return updatedItems;
              });
            } else {
              // Handle single row edit (non-family row)
              setEditedItems((prevItems) => {
                const itemIndex = prevItems.findIndex(
                  (item) =>
                    item.div === obj.div &&
                    item.subDiv === obj.subDiv &&
                    item.id === obj.id
                );
                if (itemIndex === -1) {
                  return [...prevItems, obj];
                } else {
                  const updatedItems = [...prevItems];
                  updatedItems[itemIndex] = { ...obj };
                  return updatedItems;
                }
              });
            }
          }

          return updatedRows;
        });
      } else {
        setEditedItems((prevItems) => {
          const itemIndex = prevItems.findIndex(
            (item) =>
              item.div === obj.div &&
              item.subDiv === obj.subDiv &&
              item.id === obj.id
          );

          if (itemIndex === -1) {
            return [...prevItems, obj];
          } else {
            const updatedItems = [...prevItems];
            updatedItems[itemIndex] = { ...obj };
            return updatedItems;
          }
        });
      }
    },
    [divName, projectDetails, timeFormat]
  );

  const handleEditNewRows = React.useCallback((obj) => {
    setNewItems((prevItems) => {
      const itemIndex = prevItems.findIndex(
        (item) => item.subDiv === obj.subDiv && item.index === obj.index
      );

      if (itemIndex === -1) {
        return [...prevItems, obj];
      } else {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = { ...obj };
        return updatedItems;
      }
    });
  }, []);

  const handleDeleteRow = React.useCallback((obj) => {
    setDeletedItems((prevItems) => {
      const itemIndex = prevItems.findIndex(
        (item) => item.subDiv === obj.subDiv && item.index === obj.index
      );

      if (itemIndex === -1) {
        return [...prevItems, obj];
      } else {
        const updatedItems = [...prevItems];
        updatedItems.splice(itemIndex, 1);
        return updatedItems;
      }
    });
  }, []);

  const rowExpansionStates = React.useMemo(() => {
    const states = {};
    data.forEach((item) => {
      if (item.entryID) {
        states[item.entryID] = {
          isExpanded: expandedRows[item.entryID],
          isChildVisible: item.childGroup
            ? expandedParentGroups.includes(item.childGroup)
            : false,
        };
      }
    });
    return states;
  }, [data, expandedRows, expandedParentGroups]);

  const parentIssueContractRef = React.useRef(null);
  const parentSubmittalRequiredRef = React.useRef(null);
  const parentSubmittalApprovalDueRef = React.useRef(null);

  const renderTableRow = React.useCallback(
    (item, index, items) => {
      const isParent = nestSubmittals && item.parentGroup;
      const childCount = item.childGroupCnt || 0;
      const { isExpanded, isChildVisible } =
        rowExpansionStates[item.entryID] || {};
      const isChild = nestSubmittals && item.childGroup;

      if (isParent && childCount > 0) {
        parentIssueContractRef.current = item?.issueContract;
        parentSubmittalRequiredRef.current = item?.submittalRequired;
        parentSubmittalApprovalDueRef.current = item?.submittalApprovalDue;
      }

      return (
        <SubDivisionTableItem
          key={item.entryID || index}
          data={item}
          divName={divName}
          subDivisionName={subDivisionName}
          rowindex={index}
          toggleRowCheck={toggleRowCheck}
          checkedRows={checkedRows[item.entryID]}
          handleEditRow={handleEditRow}
          handleEditNewRows={handleEditNewRows}
          handleDeleteRow={handleDeleteRow}
          isParent={isParent}
          isChild={isChild}
          childCount={childCount}
          isExpanded={isExpanded}
          onToggleExpand={() =>
            toggleRowExpansion(item.entryID, item.parentGroup)
          }
          className={`
            ${styles.tableRow}
            ${isChild ? styles.childRow : ""}
            ${isChild && !isChildVisible ? styles.hiddenChild : ""}
          `}
          nestSubmittals={nestSubmittals}
          matchCalculations={matchCalculations}
          subDivItemState={subDivItemState}
        />
      );
    },
    [
      nestSubmittals,
      divName,
      subDivisionName,
      toggleRowCheck,
      checkedRows,
      handleEditRow,
      handleEditNewRows,
      handleDeleteRow,
      rowExpansionStates,
      toggleRowExpansion,
      subDivItemState,
    ]
  );

  return (
    <div
      className={`${styles.tableContainer} ${
        projectsAccessLevelValue == 1 && styles.notAllowed
      } mt-2 px-2`}
      onClick={(e) => toggleTableVisibility(e)}
    >
      {!tableVisible &&
        (loading ? (
          <div>
            <LoadingComponent />
          </div>
        ) : (
          <>
            <table className={`${styles.table} `}>
              <thead className={`${styles.tableHead}`}>
                <tr>
                  <th
                    scope="col"
                    className="w-50"
                    style={{
                      position: "sticky",
                      left: -10,
                      zIndex: 2,
                      backgroundColor: "white",
                    }}
                  >
                    <div className={`${styles.tableHeadBgCheckBox} w-100 px-4`}>
                      <Checkbox
                        checked={allChecked && !indeterminate}
                        onChange={toggleCheckAll}
                        className=""
                        indeterminate={indeterminate}
                        disabled={
                          projectsAccessLevelValue < 2 || unsavedChanges
                        }
                      ></Checkbox>
                    </div>
                  </th>

                  <th
                    scope="col"
                    style={{
                      position: "sticky",
                      left: !nestSubmittals ? 40 : 63,
                      zIndex: 1,
                      backgroundColor: "white",
                    }}
                  >
                    {subDivisionName !== "Unlabeled - Unlabeled" ? (
                      <div
                        className={`d-flex align-items-center ${styles.tableHeadText} ${styles.tableHeadBg} pointer `}
                        style={{
                          width: 250,
                          cursor:
                            projectsAccessLevelValue < 2
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          projectsAccessLevelValue < 2
                            ? null
                            : createNewRow(subDivisionName)
                        }
                        // onClick={() =>
                        //   projectsAccessLevelValue < 2
                        //     ? null
                        //     : dispatch(showHiddenRow(subDivisionName))
                        // }
                      >
                        <img src={addIcon} alt="plus-icon" className="mx-1" />
                        <Text className={`${styles.tableHeadText}`}>
                          Add Item
                        </Text>
                      </div>
                    ) : (
                      <>
                        <div
                          className={`d-flex align-items-center ${styles.tableHeadText} ${styles.tableHeadBg}`}
                          style={{
                            width: 250,
                            backgroundColor: "#F4F4F4",
                          }}
                        >
                          <Text className={`${styles.tableHeadText}`}></Text>
                        </div>
                      </>
                    )}
                  </th>

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Submittal Number
                      </Text>
                    </div>
                  </th>

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Submittal Revision
                      </Text>
                    </div>
                  </th>

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Submittal Type
                      </Text>
                    </div>
                  </th>

                  {sortedColumns.map((key) => (
                    <th scope="col" key={key}>
                      <Popover content={alignedData[key].description || ""}>
                        <div
                          className={`${styles.tableHeadBg} ${
                            alignedData[key].label.length < 3 &&
                            alignedData[key].type === "int" &&
                            styles.smallCells
                          }`}
                        >
                          <Text
                            className={`${styles.tableHeadText}`}
                            dontWrap={false}
                          >
                            {alignedData[key].label}
                          </Text>
                        </div>
                      </Popover>
                    </th>
                  ))}

                  <th scope="col">
                    <div className={`${styles.tableHeadBg}`}>
                      <Text
                        className={`${styles.tableHeadText}`}
                        dontWrap={false}
                      >
                        Actions
                      </Text>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "white" }}>
                {[
                  ...(tableRows[subDivisionName] || []),
                  ...(newItems.filter(
                    (item) => item.subDiv === subDivisionName
                  ) || []),
                ].map((item, index, items) =>
                  renderTableRow(item, index, items)
                )}
              </tbody>
              {/* <tbody style={{ backgroundColor: "white" }}>
                {[
                  ...(tableRows[subDivisionName] || []),
                  ...(newItems.filter(
                    (item) => item.subDiv === subDivisionName
                  ) || []),
                ].map((items, index) => (
                  <SubDivisionTableItem
                    data={items}
                    key={"sub-div" + index + items.entryID}
                    divName={divName}
                    subDivisionName={subDivisionName}
                    rowindex={index}
                    toggleRowCheck={toggleRowCheck}
                    checkedRows={checkedRows[items["entryID"]]}
                    handleEditRow={handleEditRow}
                    handleEditNewRows={handleEditNewRows}
                    handleDeleteRow={handleDeleteRow}
                  />
                ))}
              </tbody> */}
            </table>
          </>
        ))}
    </div>
  );
};
export default SubDivisionTable;
